<template>
  <div class="index-wrapper">
    <title-bar
      :leftIcon="'icon-menu'"
      @onClickSelfIcon="onClickSelfIcon"
    ></title-bar>
    <div class="index-content" ref="indexContent">
      <div class="index-message" @click="gotoPage('Message')">
        <div class="message-txt">消息通知</div>
        <div class="message-count" v-if="msgNum > 0">
          {{ msgNum }}
        </div>
      </div>
      <div class="index-pages">
        <div class="pages-item" @click="gotoPage('TenantOperation')">
          <div class="item-icon">
            <img class="auto-image" src="../assets/images/u5661.png" alt="" />
          </div>
          <div class="item-title">运营部营收分析</div>
        </div>
        <div class="pages-item" @click="gotoPage('ParkingOperation')">
          <div class="item-icon">
            <img class="auto-image" src="../assets/images/u5651.png" alt="" />
          </div>
          <div class="item-title">车场营收分析</div>
        </div>
        <div class="pages-item" @click="gotoPage('ParkingData')">
          <div class="item-icon">
            <img class="auto-image" src="../assets/images/u5649.png" alt="" />
          </div>
          <div class="item-title">停车数据分析</div>
        </div>
      </div>
    </div>

    <!-- <van-popup
      position="left"
      :style="{ width: '60%', height: '100%' }"
      v-model="showSetting"
      :get-container="getDom"
    /> -->

    <popup-layer v-model="showSetting" :width="'48%'">
      <setting></setting>
    </popup-layer>
  </div>
</template>

<script>
/**
 * 将设置页面独立出来
 * 以组件方式嵌入主页面中
 */
import Setting from "./Setting.vue";
export default {
  components: {
    Setting
  },

  data: () => ({
    showSetting: false,
    msgNum: 0
  }),
  methods: {
    onClickSelfIcon() {
      this.showSetting = true;
    },

    getDom() {
      return this.$refs.indexContent;
    },

    gotoPage(pageName, params) {
      this.$router.push({ name: pageName, params });
    },

    async updateMsgNum() {
      let { data } = await this.$api.getUserMsg("1", "0");
      this.msgNum = data.total;
    }
  },

  mounted() {
    this.updateMsgNum();
  }
};
</script>

<style lang="less" scoped>
.index-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #f0f2f5;
}
.index-content {
  flex: 1;
  width: 100%;
  padding: 16px 20px;
}

.index-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  width: 100%;
  padding: 0 20px;
  margin-bottom: 14px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dadcdf;
  color: #333;
  font-weight: bold;
}

.message-count {
  padding: 2px 10px;
  border-radius: 10px;
  background-color: #ff0000;
  color: #fff;
  font-size: 12px;
}

.index-pages {
  width: 100%;

  .pages-item {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid #dadcdf;
    box-shadow: 2px 2px 6px #888;

    &:last-child {
      margin: 0;
    }

    .item-icon {
      width: 60px;
      height: 60px;
      margin-left: 20px;
      margin-right: 40px;
      border-radius: 50%;
    }

    .item-title {
      color: #333;
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>
