<template>
  <div class="setting-wrapper">
    <div class="setting-info">
      <div class="info-image">
        <img class="auto-image" :src="getAvatarUrl()" alt="" />
      </div>
      <div class="info-name">{{ userName }}</div>
      <div class="info-title">{{ roleName }}</div>
    </div>
    <div class="setting-content">
      <div class="content-item content-update">
        <div class="item-icon">
          <img class="auto-image" src="../assets/images/update.png" alt="" />
        </div>
        <div class="item-txt">检查更新</div>
      </div>
      <div class="content-item content-setup">
        <div class="item-icon">
          <img class="auto-image" src="../assets/images/setting.png" alt="" />
        </div>
        <div class="item-txt">设置</div>
      </div>
    </div>
    <div class="setting-footer">
      <div class="footer-logout" @click="onLogout">退出登录</div>
      <div class="footer-version">v0.1.1</div>
    </div>
  </div>
</template>

<script>
import { Dialog } from "vant";
import { mapState } from "vuex";
export default {
  methods: {
    onLogout() {
      Dialog.confirm({
        title: "温馨提示",
        message: "请确认退出登录"
      }).then(() => {
        this.$db.clear();
        location.reload();
      });
    },

    getAvatarUrl() {
      return this.$utils.getAvatarUrl(this.avatar);
    }
  },
  computed: {
    ...mapState({
      userName: state => state.account.userName,
      roleName: state => state.account.roleName,
      avatar: state => state.account.avatar
    })
  }
};
</script>

<style lang="less" scoped>
.setting-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.setting-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #003399;
  color: #fff;
  padding: 50px 0 16px;

  .info-image {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    margin-bottom: 16px;
    overflow: hidden;
  }

  .info-name {
    margin-bottom: 10px;
  }

  .info-title {
    padding: 4px 14px;
    border: 1px solid #fff;
    border-radius: 10px;
  }
}

.setting-content {
  flex: 1;
  width: 100%;
  padding: 18px 14px;

  .content-item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .item-icon {
      width: 28px;
      margin-right: 14px;
    }

    .item-txt {
      color: #333;
    }
  }
}

.setting-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;

  .footer-logout {
    padding: 4px 16px;
    border: 1px solid #bcbcbc;
    border-radius: 6px;
    margin-bottom: 8px;
  }

  .footer-version {
    color: #c2bccb;
    font-size: 12px;
  }
}
</style>
